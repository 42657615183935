.trending-slider button.rec-arrow {
    width: 32px;
    height: 32px;
    min-width: 32px;
    line-height: 32px;
}

.trending-slider .rec-slider-container {
    margin: 0 5px;
}

.trending-slider button:hover:enabled,
.trending-slider button:focus:enabled {
    background-color: #1da1f3;
}